import React, { ReactNode, ReactElement } from 'react';

import { TipProps } from 'bloko/blocks/drop/Tip';

export type ToolTipProps = Omit<TipProps, 'render' | 'onExternalClose' | 'show'> & { content: string };

export interface TagProps {
    /** Контент */
    children: ReactNode;
    /** Список вложенных тегов */
    items: ReactNode[];
    /** Тип компонента по назначению */
    kind?: TagKind;
    /** Текст для отображения в инпуте во время редактирования */
    text?: string;
    /** Анимировать ли тег как новый */
    isNew?: boolean;
    /** Отображать ли тег как выбранный */
    selected?: boolean;
    /** Отображать ли тег как отключенный */
    disabled?: boolean;
    /** Обработчик выбора тега */
    onSelect?: () => void;
    /** Обработчик удаления тега */
    onRemove?: () => void;
    /** Обработчик редактирования тега */
    onEdit?: (value: string) => void;
    /** Разрешать ли изменение счетчика */
    changeable?: boolean;
    /** Показывать ли счетчик как уже измененный */
    voted?: boolean;
    /** Значение счетчика */
    count?: number;
    /** Обработчик увеличения счетчика */
    onCountIncrease?: () => void;
    /** Обработчик уменьшения счетчика */
    onCountDecrease?: () => void;
    /** Развернут ли вложенный тег */
    expanded?: boolean;
    /** Обработчик изменения expanded */
    onToggle?: () => void;
    /** Дополнительный кастомный data-qa */
    additionalDataQa: string;
    /** Иконка перед текстом */
    icon?: ReactNode;
    /** Проксируется родительским компонентом @docgen-ignore */
    removable?: boolean;
    /** Проксируется родительским компонентом @docgen-ignore */
    stretched?: boolean;
    /** Проксируется родительским компонентом @docgen-ignore */
    selectable?: boolean;
    /** Проксируется родительским компонентом @docgen-ignore */
    selectableByClick?: boolean;
    /** Проксируется родительским компонентом @docgen-ignore */
    editable?: boolean;
    /** Проксируется родительским компонентом @docgen-ignore */
    countable?: boolean;
    /** Проксируется родительским компонентом @docgen-ignore */
    tooltipParams?: ToolTipProps;
    /** Проксируется родительским компонентом @docgen-ignore */
    animateNew: boolean;
    /** Проксируется родительским компонентом @docgen-ignore */
    nested?: boolean;
    /** Проксируется родительским компонентом @docgen-ignore */
    child?: boolean;
    /** Используется для работы с DOM-элементом @docgen-ignore */
    nodeRef?: React.RefObject<HTMLDivElement>;
}

export enum TagKind {
    Success = 'success',
}

export interface RenderTagFunc {
    (item: ReactElement<TagProps>, props?: Partial<TagProps>): ReactElement<TagProps>;
}
