import { createRoot } from 'react-dom/client';

import Components from 'bloko/common/core/Components';

export default Components.build({
    create(element, { Element, IconComponent, iconProps }) {
        const rootNode = document.createElement(Element ?? 'span');

        if (!Element) {
            rootNode.classList.add('bloko-icon-react-root');
        }

        element.appendChild(rootNode);

        const root = createRoot(rootNode);
        root.render(<IconComponent {...iconProps} />);

        return {
            change: (iconProps) => {
                root.render(<IconComponent {...iconProps} />);
            },
            destroy: () => {
                root.unmount();
                rootNode.remove();
            },
        };
    },
});
