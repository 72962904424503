import { cloneElement, PureComponent, Fragment } from 'react';

import SuggestLayer from 'bloko/common/constants/layersCssClasses';
import debounce from 'bloko/common/debounce';

import SuggestPicker from './SuggestPicker';
import Defaults from './defaults';
import { normalizeText, isObjectsEqual } from './utils';

import './suggest.less';

/**
 * Саджест позволяет добавить к любому текстовому полю формы возможность выбора вариантов,
 * чтобы не пришлось вводить всё слово или фразу целиком.
 */
class Suggest extends PureComponent {
    static defaultProps = {
        autoSelect: Defaults.AUTOSELECT,
        allowPicker: Defaults.ALLOW_PICKER,
        selectOnBlur: Defaults.SELECTION_ON_BLUR,
        selectExactMatchOnBlur: Defaults.SELECT_EXACT_MATCH_ON_BLUR,
        limit: Defaults.LIMIT,
        itemText: (item) => item[Defaults.FIELD],
        suggestStartInputLength: Defaults.SUGGEST_START_INPUT_LENGTH,
        debounceDelay: Defaults.THROTTLE,
        searchOnFocus: Defaults.SEARCH_ON_FOCUS,
    };

    static getDerivedStateFromProps(props, state) {
        const isControlled = typeof props.value !== 'undefined';

        if (!isControlled) {
            return null;
        }

        if (!props.value) {
            return { value: null };
        }
        const newValue = { ...props.value };

        return Suggest.getStateByValue(
            newValue,
            props.itemText,
            !(state.value && isObjectsEqual(state.value, newValue))
        );
    }

    static getStateByValue(value, itemText, hidePicker = true) {
        if (!value) {
            return { value: null };
        }

        const searchValue = itemText(value);
        const newState = {
            value,
            search: searchValue,
            preparedSearchValue: normalizeText(searchValue),
        };

        if (hidePicker) {
            newState.pickerEnabled = false;
        }

        return newState;
    }

    defaultSearchValue = this.getDefaultSearchValue();

    state = {
        pickerItems: [],
        pickerEnabled: false,
        value: this.props.defaultValue ? this.props.defaultValue : null,
        search: this.defaultSearchValue,
        preparedSearchValue: normalizeText(this.defaultSearchValue),
    };

    componentWillUnmount() {
        this.updatePickerDebounced.cancel();
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.value !== prevProps.value) {
            this.setState(Suggest.getStateByValue(this.props.value, this.props.itemText));
        }
    };

    getDefaultSearchValue() {
        const { defaultValue, defaultSearch, itemText } = this.props;
        if (defaultValue) {
            return itemText(defaultValue);
        }
        return defaultSearch || '';
    }

    isControlled = () => {
        return typeof this.props.value !== 'undefined';
    };

    inputRef = (element) => {
        const { passedInputRef } = this;
        if (passedInputRef && passedInputRef.hasOwnProperty('current')) {
            passedInputRef.current = element;
        } else if (typeof passedInputRef === 'function') {
            passedInputRef(element);
        }
        this.inputRefElement = element;
    };

    renderClonedInputElement = () => {
        const input = this.props.children;
        this.passedInputRef = input.ref;
        this.passedInputOnChange = input.props.onChange;
        this.passedInputOnBlur = input.props.onBlur;
        this.passedInputOnFocus = input.props.onFocus;
        const reassignedInputProps = {
            onChange: this.handleInputChange,
            onBlur: this.handleInputBlur,
            onFocus: this.handleInputFocus,
            ref: this.inputRef,
            source: this.props.source,
            autoComplete: 'off',
            'aria-autocomplete': 'list',
        };

        return cloneElement(input, { ...reassignedInputProps, value: this.state.search });
    };

    updatePicker = (newPreparedSearchValue) => {
        const { suggestStartInputLength, dataProvider, itemText, autoSelect, limit, allowPicker } = this.props;
        this.setState({ preparedSearchValue: newPreparedSearchValue });

        if (!allowPicker) {
            return;
        }

        if (newPreparedSearchValue.length < suggestStartInputLength) {
            this.setState({
                pickerItems: [],
                pickerEnabled: false,
            });
            return;
        }

        dataProvider(newPreparedSearchValue)
            .then((data) => {
                const { items } = data;

                // Значение инпута было изменено, результаты не актуальны
                if (newPreparedSearchValue !== this.state.preparedSearchValue) {
                    return;
                }

                this.setState({
                    pickerEnabled: true,
                    pickerItems: items.slice(0, limit),
                });

                if (autoSelect && items.length === 1 && normalizeText(itemText(items[0])) === newPreparedSearchValue) {
                    this.handleItemSelect(items[0]);
                }
            })
            .catch(() => {
                if (newPreparedSearchValue !== this.state.preparedSearchValue) {
                    return;
                }
                this.setState({
                    pickerItems: [],
                    pickerEnabled: false,
                });
            });
    };

    updatePickerDebounced = debounce(this.updatePicker, this.props.debounceDelay);

    handleInputFocus = (...rest) => {
        if (this.passedInputOnFocus) {
            this.passedInputOnFocus(...rest);
        }

        const { itemText, searchOnFocus } = this.props;
        const { search, value } = this.state;

        if (value && itemText(value) && !searchOnFocus) {
            return;
        }

        const newPreparedSearchValue = normalizeText(search);

        this.updatePickerDebounced(newPreparedSearchValue);
    };

    handleInputChange = (eventOrValue, ...rest) => {
        if (this.passedInputOnChange) {
            this.passedInputOnChange(eventOrValue, ...rest);
        }
        // TODO: Удалить вариант с event после перехода на новый инпут: PORTFOLIO-17369
        // Нужен для совместимости саджеста со старым инпутом.
        const searchValue = eventOrValue.target ? eventOrValue.target.value : eventOrValue;
        const newPreparedSearchValue = normalizeText(searchValue);

        this.setState({ search: searchValue });

        if (newPreparedSearchValue === this.state.preparedSearchValue) {
            return;
        }

        if (this.state.value) {
            this.handleItemSelect(null, searchValue);
        }

        this.updatePickerDebounced(newPreparedSearchValue);
    };

    handleInputBlur = (...rest) => {
        if (this.passedInputOnBlur) {
            this.passedInputOnBlur(...rest);
        }
        const { itemText, selectOnBlur, selectExactMatchOnBlur, allowPicker } = this.props;
        const { pickerItems, search, pickerEnabled } = this.state;

        this.setState({ pickerEnabled: false });

        if (pickerEnabled && allowPicker) {
            let selectedItem;

            if (selectOnBlur) {
                selectedItem = pickerItems[0];
            } else if (selectExactMatchOnBlur) {
                const normalizedInputValue = normalizeText(search);
                selectedItem =
                    pickerItems.find((item) => normalizeText(itemText(item)) === normalizedInputValue) || null;
            } else {
                return;
            }

            this.handleItemSelect(selectedItem);
        }
    };

    handleItemSelect = (item, searchValue) => {
        const itemClone = item ? Object.assign({}, item) : null;
        const { onChange } = this.props;

        if (onChange) {
            onChange(itemClone, searchValue);
        }

        if (!this.isControlled()) {
            this.selectItem(itemClone);
        }
    };

    handlePickerHide = () => {
        this.setState({ pickerEnabled: false });
    };

    handlePickerShow = () => {
        this.setState({ pickerEnabled: true });
    };

    selectItem(item = null) {
        this.setState(Suggest.getStateByValue(item, this.props.itemText));
    }

    renderPicker() {
        const { itemContent, itemKey, renderItems, autoHighlightFirstSuggest, layer, allowPicker } = this.props;
        const { pickerEnabled, pickerItems } = this.state;

        if (!this.inputRefElement) {
            return null;
        }

        return (
            <SuggestPicker
                element={this.inputRefElement}
                elementWrapperRef={this.props.positionElementRef}
                items={pickerItems}
                itemContent={itemContent}
                itemKey={itemKey}
                renderItems={renderItems}
                onItemSelect={this.handleItemSelect}
                autoHighlightFirstSuggest={autoHighlightFirstSuggest}
                onHide={this.handlePickerHide}
                onShow={this.handlePickerShow}
                layer={layer}
                enabled={pickerEnabled && allowPicker}
            />
        );
    }

    render() {
        return (
            <Fragment>
                {this.renderClonedInputElement()}
                {this.renderPicker()}
            </Fragment>
        );
    }
}

export default Suggest;
export { SuggestLayer };
