import React, { Key, ReactNode, RefObject } from 'react';
import ReactDOM from 'react-dom';
import Transition, { ENTERED, ENTERING } from 'react-transition-group/Transition';
import classNames from 'classnames';

import SuggestLayer from 'bloko/common/constants/layersCssClasses';
import Metrics from 'bloko/common/metrics';
import requestAnimation from 'bloko/common/requestAnimation';
import { BoundingClientRect } from 'bloko/common/types';

import SuggestPickerItem from 'bloko/blocks/suggest/SuggestPickerItem';
import SuggestPickerItems from 'bloko/blocks/suggest/SuggestPickerItems';
import cssClasses from 'bloko/blocks/suggest/cssClasses';
import Defaults from 'bloko/blocks/suggest/defaults';
import {
    createKeyboardHandler,
    setMetrics,
    getTransitionStyles,
    updateHighlight,
    TransitionStylesType,
} from 'bloko/blocks/suggest/utils';

import styles from 'bloko/blocks/suggest/suggest.less';

export interface SuggestPickerItemType extends Record<string, unknown> {
    id?: string;
    text: string;
    additional?: Record<string, unknown>;
}

export interface SuggestPickerProps<T extends SuggestPickerItemType> {
    /**
     * Ref на DOM-элемент относительно которого будет спозиционирован SuggestPicker,
     * и на события которого он будет реагировать.
     */
    element: HTMLElement;
    /**
     * Ref для переопределения элемента, относительно которого будет спозиционирован SuggestPicker,
     * по умолчанию SuggestPicker будет позиционироваться относительно element
     */
    elementWrapperRef?: RefObject<HTMLElement>;
    /** Массив элементов списка для отображения */
    items?: Array<T>;
    /** Функция рендера блока со списком элементов.
     * Может добавлять в дропдаун произвольные компоненты.
     *
     * Для списка элементов используйте `<SuggestPickerItems>`.
     *
     * Для отступов используйте переменные из стилей саджеста.
     *
     * Принимает:
     * `items` — массив элементов для рендера
     * `renderItem(item, index)` — вывод `<SuggestPickerItemType>` со всеми обработчиками событий.
     *
     * Пример результата:
     *
     * <pre>
     * ```
     * <CustomBlock>
     *     <CustomHeader/>
     *     <SuggestPickerItems>
     *         <SuggestPickerItem/>
     *         <SuggestPickerItem/>
     *         <Banner/>
     *         <SuggestPickerItem/>
     *         ...
     *     </SuggestPickerItems>
     *     <CustomFooter/>
     * </CustomBlock>
     * ```
     * </pre>
     *
     * Ещё пример результата:
     *
     * <pre>
     * ```
     * <CustomBlock>
     *     ¯\_(ツ)_/¯ По вашему запросу ничего не нашлось.
     * </CustomBlock>
     * ```
     * </pre>
     */
    renderItems?: (items: Array<T>, renderItem: (item: T, index: number) => ReactNode) => ReactNode;
    /** Коллбэк, вызываемый при выборе элемента из списка */
    onItemSelect?: (item: T) => void;
    /**
     * Функция, которая должна вернуть содержимое переданного в нее item, по умолчанию возвращает
     * item[Defaults.FIELD]
     */
    itemContent?: (item: T) => ReactNode;
    /** Функция, которая должна вернуть key для переданного в нее item, по умолчанию возвращает item.id */
    itemKey?: (item: T) => Key;
    /** Положение dropdown по оси Z (устанавливает z-index). [Возможные варианты](#suggest-layers) */
    layer?: SuggestLayer;
    /** После отображения саджеста автоматически выделять первый пункт. По enter будет выбран этот вариант */
    autoHighlightFirstSuggest?: boolean;
    /** Время анимации открытия/закрытия SuggestPicker */
    fadeTime?: number;
    /** Коллбэк, вызываемый при нажатии ESC */
    onHide?: () => void;
    /** Коллбэк вызываемый, при нажатии клавиши вниз, когда пикер скрыт */
    onShow?: () => void;
    /** Определяет доступность и видимость компонента */
    enabled?: boolean;
    /** Выводить пикер внутри видимой области экрана — нужно для отображения внутри оверлеев */
    onScreen?: boolean;
}

export interface SuggestPickerDefaultProps<T extends SuggestPickerItemType> {
    items: Array<T>;
    itemContent: (item: T) => ReactNode;
    itemKey: (item: T) => Key;
    layer: SuggestLayer;
    autoHighlightFirstSuggest: boolean;
    fadeTime: number;
    enabled: boolean;
}

const subscribe = (element: EventTarget, event: keyof HTMLElementEventMap, handler: EventListener) => {
    element.addEventListener(event, handler);
    return () => {
        element.removeEventListener(event, handler);
    };
};

/**
 * Компонент позволяет отображать список элементов, один из которых можно выбрать, поддерживается работа
 * с клавиатурой и мышью, в качестве элементов могут выступать любые ноды или строки.
 */
class SuggestPicker<T extends SuggestPickerItemType> extends React.PureComponent<
    SuggestPickerDefaultProps<T> & SuggestPickerProps<T>
> {
    static defaultProps: SuggestPickerDefaultProps<SuggestPickerItemType> = {
        autoHighlightFirstSuggest: Defaults.AUTO_SELECT_FIRST_SUGGEST,
        items: [],
        itemContent: (item): ReactNode => item.text,
        itemKey: (item): Key => item.id || item.text,
        layer: Defaults.LAYER,
        enabled: true,
        fadeTime: Defaults.DROPDOWN_FADE_TIME,
    };

    highlightedItemIndex: number = this.props.autoHighlightFirstSuggest ? 0 : -1;
    transitionStyles = getTransitionStyles(this.props.fadeTime);
    pickerRef: RefObject<HTMLDivElement> = React.createRef();
    elementMetrics: BoundingClientRect = { left: 0, top: 0, width: 0, height: 0, right: 0, bottom: 0 };
    unsubscribe: { resize?: () => void; keydown?: () => void } = {};

    startCheckingElementMetrics = requestAnimation(() => {
        if (!this.props.enabled || !this.props.element) {
            return;
        }
        const currentPositionElement =
            this.props.elementWrapperRef && this.props.elementWrapperRef.current
                ? this.props.elementWrapperRef.current
                : this.props.element;
        const currentMetrics = Metrics.getMetrics(currentPositionElement);
        const { left, top, width, height } = currentMetrics;
        const compareMetrics = (key: 'left' | 'top' | 'width' | 'height') =>
            currentMetrics[key] !== this.elementMetrics[key];

        if (Object.keys({ left, top, width, height }).some(compareMetrics)) {
            this.updatePosition();
        }

        this.startCheckingElementMetrics();
    });

    componentDidMount(): void {
        this.unsubscribe = {
            resize: subscribe(window, 'resize', requestAnimation(this.updatePosition)),
            keydown: subscribe(this.props.element, 'keydown', this.handleKeyDown as EventListener),
        };
        this.updatePosition();
        this.startCheckingElementMetrics();
    }

    componentWillUnmount(): void {
        this.unsubscribe.resize?.();
        this.unsubscribe.keydown?.();
    }

    componentDidUpdate(prevProps: SuggestPickerProps<T>): void {
        this.updatePosition();
        const { items, element, fadeTime, enabled } = this.props;
        if (enabled && !prevProps.enabled) {
            this.startCheckingElementMetrics();
        }
        if (prevProps.items !== items) {
            this.highlightedItemIndex = this.props.autoHighlightFirstSuggest ? 0 : -1;
        }
        if (prevProps.fadeTime !== fadeTime) {
            this.transitionStyles = getTransitionStyles(fadeTime);
        }
        if (prevProps.element !== element) {
            this.unsubscribe.keydown?.();
            this.unsubscribe.keydown = subscribe(element, 'keydown', this.handleKeyDown as EventListener);
        }
    }

    handleKeyDown = (event: KeyboardEvent): void => {
        const { enabled, items } = this.props;
        this.handleMouseMove = this.mouseMoveHandler;
        this.keyboardHandler(!enabled || !items.length, event, this.highlightedItemIndex);
    };

    handleMouseEnter = (index: number): void => {
        this.updateHighlight(index);
    };

    mouseMoveHandler = (index: number): void => {
        this.handleMouseMove = undefined;
        this.updateHighlight(index);
    };

    handleMouseMove: ((index: number) => void) | undefined = this.mouseMoveHandler;

    handleMouseDown = (event: React.MouseEvent, index: number): void => {
        const { items } = this.props;
        event.preventDefault();
        this.selectItem(items[index]);
    };

    emitHide = (): void => {
        this.props.onHide?.();
    };

    emitShow = (): void => {
        this.props.onShow?.();
    };

    selectItem = (item: T): void => {
        this.props.onItemSelect?.(item);
    };

    selectHighlighted = (): void => {
        if (this.highlightedItemIndex === -1) {
            return;
        }
        this.selectItem(this.props.items[this.highlightedItemIndex]);
    };

    updateHighlight = (index: number): void => {
        if (!this.pickerRef.current) {
            return;
        }
        this.highlightedItemIndex = updateHighlight(this.pickerRef.current, index);
    };

    keyboardHandler = createKeyboardHandler(this.updateHighlight, this.emitHide, this.emitShow, this.selectHighlighted);

    updatePosition = (): void => {
        if (!this.pickerRef || !this.pickerRef.current) {
            return;
        }
        const currentPositionElement =
            this.props.elementWrapperRef && this.props.elementWrapperRef.current
                ? this.props.elementWrapperRef.current
                : this.props.element;
        this.elementMetrics = setMetrics(currentPositionElement, this.pickerRef.current, 0, this.props.onScreen);
    };

    renderItem = (item: T, index: number): ReactNode => {
        const { itemKey, itemContent, autoHighlightFirstSuggest } = this.props;
        return (
            <SuggestPickerItem
                key={itemKey(item)}
                index={index}
                highlighted={index === 0 && autoHighlightFirstSuggest}
                handleMouseDown={this.handleMouseDown}
                handleMouseEnter={this.handleMouseEnter}
                handleMouseMove={this.handleMouseMove}
            >
                {itemContent(item)}
            </SuggestPickerItem>
        );
    };

    renderItems(items: Array<T>, renderItem: (item: T, index: number) => ReactNode): ReactNode {
        return <SuggestPickerItems>{items.map((item, index) => renderItem(item, index))}</SuggestPickerItems>;
    }

    render(): ReactNode {
        if (!this.props.element) {
            return null;
        }

        const { layer, fadeTime, enabled, items, renderItems } = this.props;
        const { stateStyles, defaultStyle }: TransitionStylesType = this.transitionStyles;

        return ReactDOM.createPortal(
            <Transition
                in={enabled && items.length > 0}
                appear
                mountOnEnter
                unmountOnExit
                timeout={fadeTime}
                nodeRef={this.pickerRef}
            >
                {(transitionState) => (
                    <div
                        ref={this.pickerRef}
                        style={{
                            ...defaultStyle,
                            ...stateStyles[transitionState as typeof ENTERING | typeof ENTERED],
                        }}
                        className={classNames(styles.suggest, cssClasses.layer[layer])}
                    >
                        {(renderItems || this.renderItems)(items, this.renderItem)}
                    </div>
                )}
            </Transition>,
            document.body
        );
    }
}

export default SuggestPicker;
export { SuggestLayer };
