import React, {
    ComponentPropsWithoutRef,
    FC,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    PropsWithChildren,
} from 'react';
import ReactDOM from 'react-dom';
import CSSTransition from 'react-transition-group/CSSTransition';
import classnames from 'classnames';

import { KeyCode } from 'bloko/common/constants/keyboard';
import useBreakpoint from 'bloko/common/hooks/useBreakpoint';
import useServerEnv from 'bloko/common/hooks/useServerEnv';
import { Breakpoint } from 'bloko/common/media';
import modalHelper from 'bloko/common/modalHelper';

import BottomSheetSwipe from 'bloko/blocks/modal/BottomSheetSwipe';
import ModalError from 'bloko/blocks/modal/Error';
import ModalContent from 'bloko/blocks/modal/ModalContent';
import ModalFooter from 'bloko/blocks/modal/ModalFooter';
import ModalHeader from 'bloko/blocks/modal/ModalHeader';
import ModalTitle from 'bloko/blocks/modal/ModalTitle';
import { BottomSheetContext, animationHelperFactory } from 'bloko/blocks/modal/bottomSheetUtils';

import styles from 'bloko/blocks/modal/modal.less';

interface ModalProps extends ComponentPropsWithoutRef<'div'> {
    /** Обработчик закрытия modal */
    onClose: () => void;
    /** Флаг закрытия по клику на фон */
    backgroundClick?: boolean;
    /** Флаг показа кнопки закрытия */
    closeButtonShow?: boolean;
    /** Флаг закрытия по нажатию на ESC */
    escapePress?: boolean;
    /** Указывает на строку с компонентом в исходном коде в режиме разработки. Генерируется babel-plugin-react-source */
    source?: string;
    /** Флаг открытия (рендера) модала */
    visible?: boolean;
    /** DOM нода хоста в рамках которого нужно рендерить Modal, по дефолту рендер будет в body.*/
    host?: HTMLElement;
    /** Флаг отоброжения в виде компонента BottomSheet на XS */
    useBottomSheet?: boolean;
}

/**
 * Модальное окно, блокирующее активное содержимое страницы и переводящее его в неподвижный фон.
 * Высота и ширина модального окна регулируются его содержимым.
 */
const Modal: FC<ModalProps & PropsWithChildren> = ({
    onClose,
    children,
    source,
    visible = false,
    closeButtonShow = true,
    backgroundClick = true,
    escapePress = true,
    host,
    useBottomSheet = false,
    ...modalProps
}) => {
    const isClosable = useRef(true);
    const containerRef = useRef<HTMLDivElement>(null);
    const animatedElementRef = useRef<HTMLDivElement>(null);
    const isScrollEnabled = useRef<boolean>(true);
    const isServerENV = useServerEnv();

    const breakpoint = useBreakpoint();
    const isBottomSheet = useBottomSheet && breakpoint === Breakpoint.XS;
    const bottomSheetAnimationHelper = useMemo(() => animationHelperFactory(isBottomSheet), [isBottomSheet]);

    const handleClick = useCallback((event: React.MouseEvent | React.TouchEvent) => {
        event.stopPropagation();
        isClosable.current = true;
    }, []);
    const setNonClosable = useCallback(() => {
        isClosable.current = false;
    }, []);
    const handleClose = useCallback(() => {
        if (isClosable.current) {
            onClose();

            return;
        }

        isClosable.current = true;
    }, [onClose]);

    const handleEscPress = useCallback(
        (event: KeyboardEvent) => {
            if (visible && event.keyCode === KeyCode.ESC) {
                onClose();
            }
        },
        [onClose, visible]
    );

    const enableScrollIfDisabled = () => {
        if (!isScrollEnabled.current) {
            modalHelper.enableScroll();
            isScrollEnabled.current = true;
        }
    };

    useEffect(() => {
        if (escapePress) {
            document.addEventListener('keydown', handleEscPress);
        }

        return () => {
            if (escapePress) {
                document.removeEventListener('keydown', handleEscPress);
            }
        };
    }, [escapePress, handleEscPress]);

    useEffect(() => enableScrollIfDisabled, []);

    if (isServerENV) {
        return null;
    }

    return ReactDOM.createPortal(
        <BottomSheetContext.Provider value={{ isBottomSheet, modalContainerRef: containerRef }}>
            <CSSTransition
                appear
                in={visible}
                addEndListener={(done) => {
                    if (isBottomSheet) {
                        const notify = (event: TransitionEvent) => {
                            if (event.propertyName !== 'top' || !containerRef.current) {
                                return;
                            }
                            containerRef.current.removeEventListener('transitionend', notify);
                            done();
                        };
                        containerRef.current?.addEventListener('transitionend', notify, false);
                    } else {
                        animatedElementRef.current?.addEventListener('transitionend', done);
                    }
                }}
                mountOnEnter
                unmountOnExit
                onEnter={() => bottomSheetAnimationHelper.beforeOpen(containerRef.current)}
                onEntering={() => bottomSheetAnimationHelper.open(containerRef.current)}
                onEntered={() => {
                    modalHelper.disableScroll();
                    isScrollEnabled.current = false;
                    bottomSheetAnimationHelper.afterOpen(containerRef.current);
                }}
                onExit={() => bottomSheetAnimationHelper.beforeClose(containerRef.current)}
                onExiting={() => {
                    enableScrollIfDisabled();
                    bottomSheetAnimationHelper.close(containerRef.current);
                }}
                onExited={() => bottomSheetAnimationHelper.afterClose(containerRef.current)}
                classNames={{
                    appear: styles['bloko-modal-overlay'],
                    appearActive: `${styles['bloko-modal-overlay']} ${styles['bloko-modal-overlay_visible']}`,
                    enterActive: `${styles['bloko-modal-overlay']} ${styles['bloko-modal-overlay_visible']}`,
                    enterDone: `${styles['bloko-modal-overlay']} ${styles['bloko-modal-overlay_visible']}`,
                    exit: `${classnames(styles['bloko-modal-overlay'], {
                        [styles['bloko-modal-overlay__bottom-sheet']]: useBottomSheet,
                    })}`,
                    exitActive: styles['bloko-modal-overlay'],
                }}
                nodeRef={animatedElementRef}
            >
                <div source={source} onClick={backgroundClick ? handleClose : undefined} ref={animatedElementRef}>
                    <div
                        className={classnames(
                            styles['bloko-modal-container'],
                            styles['bloko-modal-container_visible'],
                            {
                                [styles['bloko-modal-container_bottom-sheet']]: useBottomSheet,
                            }
                        )}
                        data-qa="bloko-modal"
                        ref={containerRef}
                    >
                        {useBottomSheet && (
                            <BottomSheetSwipe
                                containerRef={containerRef}
                                handleClose={handleClose}
                                handleClick={handleClick}
                            />
                        )}
                        <div
                            {...modalProps}
                            onMouseDown={setNonClosable}
                            onMouseUp={setNonClosable}
                            className={styles['bloko-modal']}
                            onClick={handleClick}
                            onTouchMove={handleClick}
                        >
                            {children}
                        </div>
                        {closeButtonShow && (
                            <div className={styles['bloko-modal-close-button']} data-qa="bloko-modal-close" />
                        )}
                    </div>
                </div>
            </CSSTransition>
        </BottomSheetContext.Provider>,
        host || document.body
    );
};

export default memo(Modal);
export { ModalHeader, ModalFooter, ModalTitle, ModalError, ModalContent };
