import { FC, PropsWithChildren, RefObject } from 'react';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';
import { SetOptional } from 'type-fest';

import styles from 'bloko/blocks/animation/fade/fade.less';

export const ANIMATION_DEFAULT_TIMEOUT = 300;

interface FadeProps extends Omit<CSSTransitionProps<HTMLElement>, 'nodeRef'> {
    nodeRef: RefObject<HTMLElement>;
}

const Fade: FC<SetOptional<CSSTransitionProps<HTMLElement>, 'timeout'> & FadeProps & PropsWithChildren> = ({
    timeout = ANIMATION_DEFAULT_TIMEOUT,
    nodeRef,
    ...props
}) => (
    <CSSTransition
        nodeRef={nodeRef}
        timeout={timeout}
        classNames={{
            enter: styles['bloko-animation-fade-enter'],
            enterActive: styles['bloko-animation-fade-enter-active'],
            exit: styles['bloko-animation-fade-exit'],
            exitActive: styles['bloko-animation-fade-exit-active'],
        }}
        exit={false}
        {...props}
    />
);

export default Fade;
