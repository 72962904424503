import { useCallback, FC, ReactNode, PropsWithChildren, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';

import { ANIMATION_TIMEOUT_MS } from 'bloko/blocks/drop/constants';
import { IconLink, IconColor, CrossScaleSmallEnclosedFalse } from 'bloko/blocks/icon';
import IconDynamic from 'bloko/blocks/icon/IconDynamic';
import { DocumentedPropsWithChildren } from 'bloko/common/helpers/types';

import styles from 'bloko/blocks/drop/InfoInline/infoInline.less';
import dropStyles from 'bloko/blocks/drop/drop.less';

interface InfoInlineProps {
    /** Флаг — рисовать ли крестик */
    showCloseButton?: boolean;
    /** Флаг отвечает за внешние отступы */
    noIndent?: boolean;
    /** Флаг отвечает за показ info, `true` - показать, `false` - скрыть */
    show?: boolean;
    /** Флаг отвечает за показ указателя, `true` - показать, `false` - скрыть */
    withPointer?: boolean;
    /** Метод-рендер контента info */
    render: () => ReactNode | null | void;
    /** Колбек вызываемый при закрытии компонента. Срабатывает в случае клика на крестик или клика вне компонента */
    onClose?: () => void;
    /** dataQa='bloko-drop-info-inline' Data-qa активного info */
    dataQa?: string;
}

const DropInfoInline: FC<DocumentedPropsWithChildren<InfoInlineProps> & PropsWithChildren> = ({
    children,
    show = false,
    showCloseButton = true,
    render,
    onClose,
    withPointer,
    dataQa = 'bloko-drop-info-inline',
    noIndent,
    ...props
}) => {
    const nodeRef = useRef(null);

    const renderFunc = useCallback(() => {
        const closeButton = () => {
            if (showCloseButton) {
                return (
                    <div className={styles['bloko-info-inline__close']}>
                        <IconDynamic>
                            <IconLink onClick={onClose}>
                                <CrossScaleSmallEnclosedFalse
                                    initial={IconColor.Gray50}
                                    highlighted={IconColor.Gray60}
                                />
                            </IconLink>
                        </IconDynamic>
                    </div>
                );
            }

            return null;
        };

        return (
            <CSSTransition
                in={show}
                timeout={{
                    enter: ANIMATION_TIMEOUT_MS,
                    exit: 0,
                }}
                unmountOnExit
                classNames={{
                    enterActive: dropStyles['bloko-drop_active-enter'],
                    enterDone: dropStyles['bloko-drop_done-enter'],
                }}
                nodeRef={nodeRef}
            >
                <div ref={nodeRef} data-qa={dataQa} {...props}>
                    <div
                        className={classnames(styles['bloko-info-inline'], {
                            [styles['bloko-info-inline_no-indent']]: noIndent,
                            [styles['bloko-info-inline_with-pointer']]: withPointer,
                        })}
                    >
                        <div
                            className={classnames(styles['bloko-info-inline__content'], {
                                [styles['bloko-info-inline__content_without-close']]: !showCloseButton,
                            })}
                        >
                            <>
                                {render()}
                                {closeButton()}
                            </>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        );
    }, [showCloseButton, render, onClose, show, withPointer, dataQa, noIndent, props]);

    return (
        <div>
            {children}
            {renderFunc()}
        </div>
    );
};

export default DropInfoInline;
